import Vue from 'vue'
import Vuex from 'vuex'

import { getToken, setToken, removeToken, getCookie, setCookie, removeCookie, setStorage, getStorage } from '@/utils/cookie';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  token: getToken(),
	  account: getStorage('account') ? JSON.parse(getStorage('account')) : null,
    routers: getCookie('routers') ? JSON.parse(getCookie('routers')) : null,
    tagsList: [],
    collapse: false
  },
  getters: {
    account: state => state.account,
    token: state => state.token,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      setToken(token);
    },
    SET_ACCOUNT: (state, account) => {
        state.account = account;
        console.log(account)
        setStorage('account',JSON.stringify(account));
    },
    SET_ROUTER: (state, router) => {
      state.routers = router;
      setCookie('routers',JSON.stringify(router));
    },
    delTagsItem(state, data) {
      state.tagsList.splice(data.index, 1);
    },
    setTagsItem(state, data) {
      state.tagsList.push(data)
    },
    clearTags(state) {
      state.tagsList = [];
    },
    closeTagsOther(state, data) {
      state.tagsList = data;
    },
    closeCurrentTag(state, data) {
      for(let i = 0,len = state.tagsList.length; i < len; i++) {
        const item = state.tagsList[i];
        if(item.path === data.$route.fullPath) {
          if(i < len - 1) {
            data.$router.push(state.tagsList[i + 1].path);
          }else if(i > 0) {
            data.$router.push(state.tagsList[i - 1].path);
          }else {
            data.$router.push("/");
          }
          state.tagsList.splice(i, 1);
          break;
        }
      }
    },
    // 侧边栏折叠
    handleCollapse(state, data) {
      state.collapse = data;
    }
  },
  actions: {
    resetToken({ commit }) {
      commit('SET_TOKEN','');
      removeToken();
    },
    resetAccount({ commit }) {
      commit('SET_ACCOUNT','');
    }
  },
  modules: {
  }
})
