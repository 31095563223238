<template>
    <div class="app-main" :class="{ 'content-collapse': collapse }">
        <tags-view />
        <div class="app-content">
            <div class="app-content-inner">
                <transition name="fade-transform" mode="out-in">
                    <keep-alive :include="tagsList">
                        <router-view :key="key"></router-view>
                    </keep-alive>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import TagsView from './TagsView';
export default {
    components: {
        TagsView
    },
    computed: {
        collapse() {
            return this.$store.state.collapse;
        },
        tagsList() {
            return this.$store.state.tagsList.map(item => item.name)
        },
        key() {
            return this.$route.path
        }
    }
}
</script>
<style scoped>

</style>