<template>
    <div class="app-sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            unique-opened
        >
            <template v-for="item in routerMenus">
                <el-menu-item :index="item.route" :key="item.route">
                    <router-link :to="item.route"><i :class="item.icon"></i><span>{{ item.route_name }}</span></router-link>
                </el-menu-item>
                <!-- <template v-if="item.subs">
                    <el-submenu :index="item.path" :key="item.path">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.path"
                                :key="subItem.path"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.path"
                                >
                                    <router-link :to="threeItem.path"><span>{{ threeItem.title }}</span></router-link>
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.path"
                                :key="subItem.path"
                            ><router-link :to="subItem.path"><span>{{ subItem.title }}</span></router-link></el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.path" :key="item.path">
                        <template #title>
                            <router-link :to="item.path"><i :class="item.icon"></i><span>{{ item.title }}</span></router-link>
                        </template>
                    </el-menu-item>
                </template> -->
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            menus: [
                {
                    icon: 'el-icon-lx-home',
                    path: '/dashboard',
                    title: '首頁'
                },
                {
                    icon: 'el-icon-lx-home',
                    path: '/base-setting',
                    title: '基礎設定',
                    // subs: [
                    //     {
                    //         path: 'message-charge',
                    //         title: '推播管理',
                    //     }
                    // ]
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/back-end-admin-charge',
                    title: '後台管理員管理'
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/front-end-admin-charge',
                    title: '管理員管理'
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/member-charge',
                    title: '會員管理'
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/discussion-charge',
                    title: '討論區管理'
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/theme-charge',
                    title: '主題區管理'
                },
                {
                    icon: 'el-icon-lx-cascades',
                    path: '/message-charge',
                    title: '推播管理'
                }
            ],
            routerMenus: [
                {
                    icon: 'el-icon-lx-home',
                    route: '/dashboard',
                    route_name: '首頁'
                },
                // {
                //     icon: 'el-icon-lx-cascades',
                //     route: '/discussion-charge',
                //     route_name: '討論區管理'
                // },
                // {
                //     icon: 'el-icon-lx-cascades',
                //     route: '/theme-charge',
                //     route_name: '主題區管理'
                // },
                // {
                //     icon: 'el-icon-lx-cascades',
                //     route: '/message-charge',
                //     route_name: '推播管理'
                // }
            ]
        }
    },
    computed: {
        onRoutes() {
            let path = this.$route.path;
            let pathAffix = path.split("/")[1];
            // console.log('pathAffix',pathAffix)
            return '/' + pathAffix
            // return this.$route.path.replace("/","");
        },
        collapse() {
            return this.$store.state.collapse
        }
    },
    mounted(){
        // console.log(this.$store.state.routers)
        this.routerMenus = [...this.routerMenus,...this.$store.state.routers]
    }
}
</script>
<style scoped>

</style>
