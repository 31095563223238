import Vue from 'vue'
import router from './router'
import store from './store'

// 引入elementui
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element-variables.scss';
import zhLang from 'element-ui/lib/locale/lang/zh-TW';
import locale from '@/assets/locale/cn';
import './assets/css/icon.scss';
Vue.use(ElementUI,{
  locale: {...zhLang}
})
import Directives from './utils/directives/index';
Vue.use(Directives)
// 引入axios
import http from '@/utils/http';
import { get, post } from '@/utils/http';
Vue.prototype.$http = http;
Vue.prototype.$get = get;
Vue.prototype.$post = post;


// util
import util from '@/utils/util';
Vue.prototype.$util = util;



Vue.config.productionTip = false

import App from './App.vue'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
