<template>
    <div class="tags-view" v-if="showTags">
        <ul class="tags-ul">
            <li 
                class="tags-li"
                v-for="(item,index) in tagsList"
                :class="{'active': isActive(item.path)}"
                :key="index">
                <router-link :to="item.fullPath" class="tags-li-title">{{ item.title }}</router-link>
                <span class="tags-li-icon" @click="closeTags(index)">
                    <i class="el-icon-close"></i>
                </span>
            </li>
        </ul>
        <!-- <div class="tags-close-box">
            <el-dropdown @command="handleTags">
                <el-button size="mini" type="primary">
                    标签选项
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu size="small">
                        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                        <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div> -->
    </div>
</template>
<script>
export default {
    name: 'TagsView',
    computed: {
        tagsList() {
            return this.$store.state.tagsList
        },
        showTags() {
            return this.tagsList.length > 0
        }
    },
    watch: {
        $route(newValue) {
            this.setTags(newValue)
        }
    },
    created(){
        this.setTags(this.$route);
    },
    methods: {
        isActive(path) {
            return path === this.$route.path
        },
        // 关闭单个标签
        closeTags(index) {
            const delItem = this.tagsList[index];
            this.$store.commit('delTagsItem',{ index });
            const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
            if(item) {
                delItem.path === this.$route.path && this.$router.push(item.path);
            }else {
                this.$router.push('/');
            }
        },
        // 关闭所有标签
        closeAll() {
            this.$store.commit('clearTags');
            this.$router.push('/');
        },
        // 关闭其他标签
        closeOther() {
            const curItem = this.tagsList.filter(item => {
                return item.path === this.$route.path;
            });
            this.$store.commit('closeTagsOther', curItem);
        },
        // 設定标签
        setTags(route) {
            const isExist = this.tagsList.some(item => {
                // return item.path === route.fullPath
                return item.path === route.path
            });
            if(!isExist) {
                if(this.tagsList.length >= 8) {
                    this.$store.commit('delTagsItem', { index: 0 });
                }
                this.$store.commit('setTagsItem', {
                    name: route.name,
                    title: route.meta.title,
                    fullPath: route.fullPath,
                    path: route.path
                })
            }
        },
        handleTags(command) {
            command === 'other' ? this.closeOther() : this.closeAll();
        }
    }
}
</script>
<style scoped>

</style>