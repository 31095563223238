import {apiUploadFile} from "@/api/api";

let isMobile =  () => {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      return true // 手机端
    } else {
      return false // alert('PC端')
    }
}
let getNowFormatDate =  () => {
  var date = new Date();
  var seperator1 = "-";
  var seperator2 = ":";
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
      month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
  }
  // var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
  //         + " " + date.getHours() + seperator2 + date.getMinutes()
  //         + seperator2 + date.getSeconds();
  var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + " " + date.getHours() + seperator2 + date.getMinutes();
  return currentdate;
}
export default {
    isMobile,
    getNowFormatDate
}


function base64ImgtoFile(dataurl, filename = 'file') {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}
export async function formatContent (content) {
    const parseContent = JSON.parse(content)
    for (const i of parseContent) {
        if (i.insert && i.insert.image) {
            if (i.insert.image.match(/data:image\/.*/)) {
                console.log('true')
                const file = base64ImgtoFile(i.insert.image)
                const formData = new FormData()
                formData.append('file', file)
                formData.append('type',file.type)

                const res = await apiUploadFile(formData)
                i.insert.image = res
            }
        }
    }
    return JSON.stringify(parseContent)
}
