import store from '@/store'
export default {
  inserted(el,bindling){
    //bindling.
      let permissions = store.state.account.permissions
      let perVal = bindling.value;
      if(bindling.value){
      //假设某用户对某模块只有添加和删除的权限
      //这个权限信息(即pers)
          let hasPer = permissions.some(item=>{
              return item.name == perVal
          });
           //没有权限就先隐藏此元素吧
          if(!hasPer){
              el.style.display="none"
          }
      }
  }
}
