import { post, get, axios, upload } from '@/utils/http';

/**common**/
// 文件上傳
export const apiUploadFile = params => upload('api/common/uploadFile', params)

// 登录
export const apiLogin = params => post('api/login', params)
// 登出
export const apiLogout = params => get('api/logout', params)

// 获取后台配置的菜单
export const apiGetTotal = params => get('api/analysis/total', params)

/**輪播圖**/
// banner列表
export const apiGetBannerList = params => get('api/banners/lists', params)
// 新增banner
export const apiCreateBanner = params => post('api/banners/create', params)
// 編輯banner
export const apiEditBanner = params => post('api/banners/edit', params)
// 移除banner
export const apiRemoveBanner = params => post('api/banners/remove', params)
// banner詳情
export const apiReadBanner = params => get('api/banners/read', params)
// banner状态修改
export const statusChange = params => post('api/banners/statusChange', params)

/**主题分類**/
// 分類樹
export const apiGetTypeTree = params => get('api/post-type/typeTree', params)
// 修改
export const apiEditTypeTree = params => post('api/post-type/edit', params)
// 刪除
export const apiRemoveTypeTree = params => post('api/post-type/remove', params)
// 拖拽排序
export const apiResortTypeTree = params => post('api/post-type/resort', params)

/**後臺管理員**/
// 後臺管理員列表
export const apiGetAdminList = params => get('api/admin/lists', params)
// 修改後臺管理員賬號狀態
export const apiAdminStatusChange = params => post('api/admin/statusChange', params)
// 後台管理員權限
export const apiGetAdminPermissions = params => get('api/admin/permissions', params)
// 後台管理員詳情
export const apiReadAdmin = params => get('api/admin/read', params)
// 新建後臺管理員
export const apiCreateAdmin = params => post('api/admin/create', params)
// 修改後臺管理員
export const apiEditAdmin = params => post('api/admin/edit', params)
// 删除後臺管理員
export const apiRemoveAdmin = params => post('api/admin/remove', params)
// 获取未设置管理员的app会员
export const getMembersWithAppAdmin = params => get('api/admin/getMembersWithAppAdmin', params)

/**管理員**/
// 根據角色獲取權限
export const apiGetManagerPermission = params => get('api/manager/get-permission-by-role', params)
// 修改角色權限
export const apiSetManagerPermission = params => post('api/manager/set-permission-by-role', params)


/**會員**/
// 會員列表
export const apiGetMemberList = params => get('api/members/lists', params)
// 新增會員
export const apiCreateMember = params => post('api/members/create', params)
// 會員詳情
export const apiReadMember = params => get('api/members/read', params)
// 刪除會員
export const apiRemoveMember = params => post('api/members/remove', params)
// 修改會員狀態
export const apiMemberStatusChange = params => post('api/members/statusChange', params)
// 禁用會員
export const apiBannedMember = params => post('api/members/banned', params)
// 編輯會員
export const apiEditMember = params => post('api/members/edit', params)
// 會員導出
export const apiExportMember = params => get('api/export/export_members', params)

/**討論區**/
// 討論區管理
export const apiGetDiscussionList = params => get('api/discussion/lists', params)
// 討論新增
export const apiCreateDiscussion = params => post('api/discussion/create', params)
// 討論狀態修改
export const apiDiscussionStatusChange = params => post('api/discussion/statusChange', params)
// 討論置頂
export const apiTopDiscussion = params => post('api/discussion/top', params)
// 討論詳情
export const apiReadDiscussion = params => get('api/discussion/read', params)
// 編輯討論
export const apiEditDiscussion = params => post('api/discussion/edit', params)
// 刪除討論
export const apiRemoveDiscussion = params => post('api/discussion/remove', params)
// 審核討論
export const apiReviewDiscussion = params => post('api/discussion/review', params)
// 討論區評論
export const apiAddComment = params => post('api/discussion/addComment', params)
// 討論區評論移除
export const apiRemoveComment = params => post('api/discussion/removeComment', params)
// 討論區評論點讚
export const apiAdmireComment = params => post('api/discussion/commentAdmire', params)

//評論置頂

export const commentTop = params => post('api/comment/top', params)

/**主題區**/
// 主題區管理
export const apiGetThemeList = params => get('api/theme/lists', params)
// 主題新增
export const apiCreateTheme = params => post('api/theme/create', params)
// 主題狀態修改
export const apiThemeStatusChange = params => post('api/theme/statusChange', params)
// 主題置頂狀
export const apiTopTheme = params => post('api/theme/top', params)
// 主題詳情
export const apiReadTheme = params => get('api/theme/read', params)
// 編輯主題
export const apiEditTheme = params => post('api/theme/edit', params)
// 刪除討論
export const apiRemoveTheme = params => post('api/theme/remove', params)
// 審核主題
export const apiReviewTheme = params => post('api/theme/review', params)
// 主題區評論
export const apiAddThemeComment = params => post('api/theme/addComment', params)
// 主題區評論移除
export const apiRemoveThemeComment = params => post('api/theme/removeComment', params)
// 主題區評論點讚
export const apiAdmireThemeComment = params => post('api/theme/commentAdmire', params)
// 清除最新评论
export const apiClearNewComment = params => post('api/theme/clearNewComment', params)

// 評論列表
export const apiCommentListData = params => get('api/comment/lists-with-offset', params)


/**粉絲團**/
// 粉絲團管理
export const apiGetFansList = params => get('api/fans-team/lists', params)
// 粉絲團新增
export const apiCreateFans = params => post('api/fans-team/create', params)
// 粉絲團狀態修改
export const apiFansStatusChange = params => post('api/fans-team/statusChange', params)
// 粉絲團置頂狀
export const apiTopFans = params => post('api/fans-team/top', params)
// 粉絲團詳情
export const apiReadFans = params => get('api/fans-team/read', params)
// 編輯粉絲團
export const apiEditFans = params => post('api/fans-team/edit', params)
// 刪除討論
export const apiRemoveFans = params => post('api/fans-team/remove', params)
// 審核粉絲團
export const apiReviewFans = params => post('api/fans-team/review', params)
// 粉絲團評論
export const apiAddFansComment = params => post('api/fans-team/addComment', params)
// 粉絲團評論移除
export const apiRemoveFansComment = params => post('api/fans-team/removeComment', params)
// 粉絲團評論點讚
export const apiAdmireFansComment = params => post('api/fans-team/commentAdmire', params)
// 清除最新评论
export const apiClearNewFansComment = params => post('api/fans-team/clearNewComment', params)


// 推播列表
export const apiGetBroadcastList = params => get('api/broadcast/lists', params)
// 编辑推播
export const apiEditBroadcast = params => post('api/broadcast/edit', params)
// 新建推播
export const apiCreateBroadcast = params => post('api/broadcast/create', params)
// 推播刪除
export const apiRemoveBroadcast = params => post('api/broadcast/remove', params)
// 调查统计结果
export const apiGetSurveyResult = params => post('api/broadcast/survey-result', params)
// 推播详情
export const apiReadBroadcast = params => get('api/broadcast/read', params)


// 導出推播調研統計
export const exportBroadcatsuming = params => post('api/export/export_broadcatsuming', params)
// 地区
export const apiGetArea = params => get('api/common/area', params)

// 配置公共配置
export const apiSetSetting = params => post('api/setting/set', params)
// 獲取公共配置
export const apiGetSetting = params => post('api/setting/get', params)

// 獲取app管理員類型
export const apiGetRoles = params => get('api/common/roles', params)

// 登录管理员的會員身份
export const apiGetAdminMemberIdentitys = params => get('api/admin/getAdminMemberIdentitys', params)

// 分享文章
export const appReadInfo = params => get('app/posts/read', params)
// 討論置顶到最新分类
export const apiTopDiscussionNewest = params => post('api/discussion/top-newest', params)
// 主題置顶到最新分类
export const apiTopThemeNewest = params => post('api/theme/top-newest', params)
// 討論區调整评论可见性
export const apiDiscussionCommentVisable = params => post('api/discussion/comment-visable', params)
// 主題區调整评论可见性
export const apiThemeCommentVisable = params => post('api/theme/comment-visable', params)
// 粉絲團區调整评论可见性
export const apiFansCommentVisable = params => post('api/fans-team/comment-visable', params)
