<template>
    <div class="app-container" :class="[isMobile ? 'is-mobile':'is-pc']">
        <headbar />
        <sidebar />
        <app-main />
    </div>
</template>
<script>
import Headbar from './Headbar';
import Sidebar from './Sidebar';
import AppMain from './AppMain';
export default {
    name: 'Layout',
    components: {
        Headbar,
        Sidebar,
        AppMain
    },
    computed: {
        isMobile(){
            let flag = false;
            if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
                flag =  true; // 手机端
            } else {
                flag =  false // alert('PC端')
            }
            return flag;
        }
    }

}
</script>
<style scoped>

</style>
