import Cookies from 'js-cookie';
const TokenKey = 'token';
export function getToken() {
    return Cookies.get(TokenKey)
}
export function setToken(token) {
    return Cookies.set(TokenKey, token)
}
export function removeToken() {
    return Cookies.remove(TokenKey)
}
// ***********************************
export function getCookie(cookieKey) {
	return Cookies.get(cookieKey)
}
export function setCookie(cookieKey,value) {
    return Cookies.set(cookieKey, value)
}
export function removeCookie(cookieKey) {
    return Cookies.remove(cookieKey)
}
export function setStorage(storageKey,value) {
    return localStorage.setItem(storageKey, value)
}
export function getStorage(storageKey) {
	return localStorage.getItem(storageKey)
}
export function removeStorage(storageKey) {
    return localStorage.removeItem(storageKey)
}