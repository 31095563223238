import axios from 'axios';
import qs from 'qs';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken } from '@/utils/cookie';
import { SERVER_URL } from '@/utils/url';

const service = axios.create({
    baseURL: SERVER_URL,
    timeout: 50000
})

// request拦截
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Authorization'] = store.getters.token;
        }
        // config.headers['Content-Type'] = 'multipart/form-data'
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)
// response拦截
service.interceptors.response.use(
    response => {
        if(response.status === 200 && response.data.code == 0) {
            return response.data;
        }else {
            if(response.data.code == 1100) {
                store.dispatch('resetToken');
                router.push('/admin_gate');
            }
            Message({
                message: response.data.msg,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(response.data)
        }
    },
    error => {
        return Promise.reject(error);
    }
)

/**
 * get方法 对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        service.get(SERVER_URL + url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * post方法 对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    // console.log('params',typeof params)
    const postData = qs.stringify(params);
    let headers = {'Content-Type': 'multipart/form-data'};
    return new Promise((resolve,reject) => {
        service.post(SERVER_URL + url, postData).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * post方法 对应upload请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function upload(url, params) {
    let headers = {'Content-Type': 'multipart/form-data'};
    return new Promise((resolve,reject) => {
        service.post(SERVER_URL + url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    })
}


export default service;

