<template>
    <div class="app-header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChange">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <!-- <div class="app-logo"><a href="javascript:;">486先生</a></div> -->
        <div class="app-logo"><a href="javascript:;"><img src="@/assets/images/logo.png" alt=""></a></div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 消息中心 -->
                <!-- <div class="btn-bell">
                    <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
                <!-- 用户头像 -->
                <div class="user-avatar">
                    <!-- <img src="~@/assets/images/avatar.jpg" alt=""> -->
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="loginOut">退出登錄</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                 </el-dropdown>
            </div>
        </div>
    </div>
</template>
<script>
import { apiLogout } from '@/api/api';
export default {
    name: 'Headbar',
    data(){
        return {
            fullscreen: false,
            name: 'linxin',
            message: 2
        }
    },
    computed: {
        username() {
            let username = this.$store.getters.account.account;
            return username
        },
        collapse() {
            return this.$store.state.collapse
        }
    },
    methods: {
        logout(){
            let _this = this;
            apiLogout().then(res => {
                console.log('apiLogout-res',res);
                _this.$store.dispatch('resetToken');
                _this.$message({
                    type: "success",
                    message: "登出成功!",
                    duration: 1500,
                });
                localStorage.removeItem('ms_username');
                setTimeout(() => {
                    this.$router.push('/admin_gate')
                }, 1500);
            })
        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if(command == 'loginOut') {
                this.logout();
            }
        },
        // 侧边栏折叠
        collapseChange() {
            this.$store.commit("handleCollapse", !this.collapse)
        }
    },
    mounted(){
        // if(document.body.clientWidth < 1500) {
        //     this.collapseChange()
        // }
    }
}
</script>
<style scoped>

</style>
