import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts/Layout.vue';
import { getToken } from '@/utils/cookie';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: '首頁'
        },
        component: () => import('@/views/dashboard/dashboard')
      },
      {
        path: '/base-setting',
        name: 'base-setting',
        component: () => import('@/views/base-setting/base-setting'),
        children:[
          {
            path: '',
            meta: {
              title: '基礎設定'
            },
            name: 'base-setting-table',
            component: () => import('@/views/base-setting/list'),
          },
          {
            path: 'add',
            meta: {
              title: '新增banner'
            },
            name: 'base-setting-add',
            component: () => import('@/views/base-setting/add'),
          },
          {
            path: 'read',
            meta: {
              title: '查看/編輯banner'
            },
            name: 'base-setting-read',
            component: () => import('@/views/base-setting/read'),
          }
        ]
      },
      {
        path: '/front-end-admin-charge',
        name: 'front-end-admin-charge',
        meta: {
          title: '管理員管理'
        },
        component: () => import('@/views/front-end-admin-charge/front-end-admin-charge')
      }
    ]
  },
  // {
  //   path: '/base-setting',
  //   name: 'Layout',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/',
  //       name: 'base-setting',
  //       meta: {
  //         title: '基礎設定'
  //       },
  //       component: () => import('@/views/base-setting/base-setting')
  //     },
  //     {
  //       path: 'add',
  //       name: 'base-setting-add',
  //       meta: {
  //         title: '新增Banner'
  //       },
  //       component: () => import('@/views/base-setting/add')
  //     },
  //     {
  //       path: 'edit',
  //       name: 'base-setting-edit',
  //       meta: {
  //         title: '编辑banner'
  //       },
  //       component: () => import('@/views/base-setting/edit')
  //     },
  //     {
  //       path: 'view',
  //       name: 'base-setting-view',
  //       meta: {
  //         title: '查看banner'
  //       },
  //       component: () => import('@/views/base-setting/edit')
  //     }
  //   ]
  // },
  {
    path: '/back-end-admin-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'back-end-admin-charge',
        meta: {
          title: '後台管理員管理'
        },
        component: () => import('@/views/back-end-admin-charge/back-end-admin-charge')
      },
      {
        path: 'add',
        name: 'back-end-admin-add',
        meta: {
          title: '新增管理員'
        },
        component: () => import('@/views/back-end-admin-charge/add')
      },
      {
        path: 'read',
        name: 'back-end-admin-read',
        meta: {
          title: '查看/編輯管理員'
        },
        component: () => import('@/views/back-end-admin-charge/read')
      }
    ]
  },
  {
    path: '/member-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'member-charge',
        meta: {
          title: '會員管理'
        },
        component: () => import('@/views/member-charge/member-charge')
      },
      {
        path: 'add',
        name: 'member-add',
        meta: {
          title: '新增會員'
        },
        component: () => import('@/views/member-charge/add')
      },
      {
        path: 'read',
        name: 'member-read',
        meta: {
          title: '基本資料'
        },
        component: () => import('@/views/member-charge/read')
      }
    ]
  },
  {
    path: '/discussion-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'discussion-charge',
        meta: {
          title: '討論區管理'
        },
        component: () => import('@/views/discussion-charge/discussion-charge')
      },
      {
        path: 'read',
        name: 'discussion-read',
        meta: {
          title: '討論區查看/編輯'
        },
        component: () => import('@/views/discussion-charge/read')
      },
      {
        path: 'add',
        name: 'discussion-add',
        meta: {
          title: '討論區新增'
        },
        component: () => import('@/views/discussion-charge/add')
      }
    ]
  },
  {
    path: '/theme-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'theme-charge',
        meta: {
          title: '主題區管理'
        },
        component: () => import('@/views/theme-charge/theme-charge')
      },
      {
        path: 'read',
        name: 'theme-read',
        meta: {
          title: '主題區查看/編輯'
        },
        component: () => import('@/views/theme-charge/read')
      },
      {
        path: 'add',
        name: 'theme-add',
        meta: {
          title: '主題區新增'
        },
        component: () => import('@/views/theme-charge/add')
      }
    ]
  },
  {
    path: '/fans-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'fans-charge',
        meta: {
          title: '粉絲團管理'
        },
        component: () => import('@/views/fans-charge/fans-charge')
      },
      {
        path: 'read',
        name: 'fans-charge',
        meta: {
          title: '粉絲團查看/編輯'
        },
        component: () => import('@/views/fans-charge/read')
      },
      {
        path: 'add',
        name: 'fans-charge',
        meta: {
          title: '粉絲團新增'
        },
        component: () => import('@/views/fans-charge/add')
      }
    ]
  },
  {
    path: '/message-charge',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'message-charge',
        meta: {
          title: '推播管理'
        },
        component: () => import('@/views/message-charge/message-charge')
      }
    ]
  },
  {
    path: '/admin_gate',
    name: 'admin_gate',
    component: () => import('@/views/login/login')
  },
  {
    path: '/share/:id',
    name: 'share',
    component: () => import('@/views/share/share')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = getToken();
  if(!token && to.path !== '/admin_gate' && to.name !== 'share') {
    return next('/403');
  }else if(to.meta.permission) {
    // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    if (role === 'admin') {
      return next()
    } else {
      return next('/403')
    }
  }else {
    return next();
  }
})




export default router
